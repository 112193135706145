/* eslint-disable import/no-mutable-exports */

export enum ROUTE_PATHS {
  LOGIN = '/login',
  SETTINGS = '/settings',
  CONTACTS = '/contacts',
  QUOTE = '/quote',
  DASHBOARD = '/dashboard',
  FINAL_QUOTE = '/finalapplication/finaldetails',
  FINAL_QUOTE_2 = '/finalapplication/finalquotes',
  IFRAME = '/finalapplication/iframe',
  HOME = '/',
  PAYMENT = '/finalapplication/payment',
  RETRIEVE_QUOTE = '/retrievequote',
  ZIP_ERROR = '/home/ziperror',
  ZIP_VALIDATION = '/application/zipvalidation/',
  START_APPLICATION = '/application/startapplication',
  VEHICLE_DETAILS = '/application/vehicledetails',
  ABOUT_YOU = '/application/aboutyou',
  OPERATOR_DETAILS = '/application/operatordetails',
  OTHER_QUESTIONS = '/application/otherquestions',
  OPERATOR_ASSIGNMENT = '/application/operatorassignment',
  SAFE_RIDER_PROGRAM = '/application/safe-rider-program',
  QUOTE_PAGE = '/application/quotepage',
  PROOF_OF_PURCHASE = '/finalapplication/purchase',
  DYNAMICPAGE = '/finalapplication/purchase/[...locator]',
  VIEW_POLICY = '/viewpolicy',
  VIEW_PROFILE = '/viewprofile',
  ALL_CLAIMS = '/claims/allclaims',
  VIEW_CLAIMS = '/claims/viewclaims',
  VEHICLE_ENDORSEMENT = '/endorsement/vehicledetails',
  OPERATOR_ENDORSEMENT = '/endorsement/operatordetails',
  INITIAL_QUOTE_ENDORSEMENT = '/endorsement/initialquote',
  FINAL_DETAILS_ENDORSEMENT = '/endorsement/finaldetails',
  FINAL_QUOTE_ENDORSEMENT = '/endorsement/finalquotes',
  PURCHASE_ENDORSEMENT = '/endorsement/purchase',
  ESIGN_ENDORSEMENT = '/endorsement/esign',
  BOUNDLESS_HOME_PAGE = 'https://www.boundlessrider.com/',
  BOUNDLESS_PRIVACY_POLICY = 'https://www.boundlessrider.com/privacy-policy/',
  BOUNDLESSRIDER_FAQ = 'https://learn.boundlessrider.com/knowledge/our-insurance-products/',
  BOUNDLESS_WAITLIST_REQUEST = 'https://www.boundlessrider.com/contact-us/waitlist-request-form/',
  CUSTOMER_PORTAL_TERMS = 'https://boundlessrider.com/customer-portal-terms/'
}

export enum IMAGE_PATHS {
  OPEN_GRAPH = '/images/global/og.webp',
  BOUND_LOGO = '/images/global/Bound_Logo.svg',
  M_B = '/images/global/MB.svg',
  ATV_UTV_PLAIN = '/images/global/atv-utv-plain.svg',
  DIRTBIKE_PLAIN = '/images/global/dirtbike-plain.svg',
  EBIKE_PLAIN = '/images/global/ebike-plain.svg',
  SNOWMOBILE_PLAIN = '/images/global/snowmobile-plain.svg',
  PAGE_NOT_FOUND = '/images/404/page-not-found.svg',
  MAN_DOOR = '/images/auth/login/man-door.svg',
  COMPLETE_PASSWORD_RESET = '/images/auth/complete-password-reset/man-shield.svg',
  WOMAN_SIGNING_UP = '/images/auth/register/woman-signing-up.svg',
  TEXT_FIELD = '/images/auth/request-password-reset/text-field.svg',
  WOMAN_CONFIRMING = '/images/auth/resend-registration-link/woman-confirming.svg',
  CONTACTS = '/images/contacts/contacts.svg',
  MAIL_ICON = '/images/contacts/mail-icon.svg',
  IMAGE_ICON = '/images/contacts/image-icon.svg',
  IMAGE_PHONE = '/images/contacts/icon-phone.png',
  MOBILE_MENU = '/images/contacts/mobilemenu.svg',
  CIRCLE_DARK = '/images/contacts/circle-dark.svg',
  CIRCLE_LIGHT = '/images/contacts/circle-light.svg',
  SAFE_RIDER_BG_IMAGE = '/images/contacts/safeRiderImage.png',
  DASHBOARD_ICON = '/images/contacts/dashboard-icon.svg',
  MANAGE_POLICY_ICON = '/images/contacts/manage-policy.svg',
  PAYMENT_CARD_ICON = '/images/contacts/payment-card.svg',
  REPORT_CLAIM_ICON = '/images/contacts/report-claim.svg',
  DOWNLOAD_ICON = '/images/contacts/download-icon.svg',
  MOTORCYCLE_GRAY_BG = '/images/vehicles/motorcycle-gray-bg.svg',
  ATV_UTV_GRAY_BG = '/images/vehicles/atv-utv-gray-bg.svg',
  DIRTBIKE_GRAY_BG = '/images/vehicles/dirtbike-gray-bg.svg',
  EBIKE_GRAY_BG = '/images/vehicles/ebike-gray-bg.svg',
  SNOWMOBILE_GRAY_BG = '/images/vehicles/snowmobile-gray-bg.svg',
  MOTORCYCLE_SELECTED = '/images/vehicles/motorcycle-orange.svg',
  ATV_UTV_SELECTED = '/images/vehicles/atv-utv-orange.svg',
  DIRTBIKE_SELECTED = '/images/vehicles/dirtbike-orange.svg',
  EBIKE_SELECTED = '/images/vehicles/ebike-orange.svg',
  SNOWMOBILE_SELECTED = '/images/vehicles/snowmobile-orange.svg',
  PERFECT_ORANGE = '/images/vehicles/perfect-orange.svg',
  OKAY_ORANGE = '/images/vehicles/okay-orange.svg',
  NOT_GOOD_ORANGE = '/images/vehicles/notgood-orange.svg',
  PERFECT_WHITE = '/images/vehicles/perfect-white.svg',
  OKAY_WHITE = '/images/vehicles/okay-white.svg',
  NOT_GOOD_WHITE = '/images/vehicles/notgood-white.svg',
  BIKE_OUTLINE = '/images/global/bike-outline.svg',
  ARROW_RIGHT = '/images/global/arrow-right.svg',
  DONE_MARK = '/images/global/done-mark.svg',
  EDIT_PENCIL = '/images/global/edit.svg',
  PLUS_CIRCLE = '/images/global/plus-circle.svg',
  TRASH_ICON = '/images/global/trash.svg',
  SUMMARY_MOTORCYCLE = '/images/vehicles/summary-motorcycle.svg',
  SUMMARY_ATVUTV = '/images/vehicles/summary-atvutv.svg',
  SUMMARY_DIRTBIKE = '/images/vehicles/summary-dirtbike.svg',
  SUMMARY_EBIKE = '/images/vehicles/summary-ebike.svg',
  SUMMARY_SNOWMOBILE = '/images/vehicles/summary-snowmobile.svg',
  PERSON_ICON = '/images/global/person.svg',
  ZIP_ERROR = '/images/global/ziperror-bike-new.png',
  APPSTORE_IMAGE = '/images/global/appstore.png',
  PLAYSTORE_IMAGE = '/images/global/playstore.png',
  QUESTION_MARK_CIRCLE = '/images/global/question-mark-circle.svg',
  LEFT_ARROW_CIRCLE = '/images/global/arrow-circle-left.svg',
  LEFT_ARROW = '/images/contacts/arrow-ios-left.svg',
  EXCELLENT = '/images/global/excellent-cs.svg',
  VERY_GOOD = '/images/global/verygood-cs.svg',
  GOOD = '/images/global/good-cs.svg',
  FAIR = '/images/global/fair-cs.svg',
  POOR = '/images/global/poor-cs.svg',
  EXCELLENT_ORANGE = '/images/global/excellent-orange.svg',
  VERY_GOOD_ORANGE = '/images/global/verygood-orange.svg',
  GOOD_ORANGE = '/images/global/good-orange.svg',
  FAIR_ORANGE = '/images/global/fair-orange.svg',
  POOR_ORANGE = '/images/global/poor-orange.svg',
  ERROR_ICON = '/images/global/error.svg',
  BANNER = '/images/global/Insurance App - Celebratory Banner.png',
  DASHBOARD_ICON_YELLOW = '/images/global/dashboard-icon-yellow.svg',
  CANCEL = '/images/global/cancel.svg',
  ARROW_RIGHT_ORANGE = 'images/global/arrow-right-orange.svg',
  CLOSE = '/images/global/close-icon.svg',
  VEHICLE_COVERAGE = '/images/vehicles/vehicle-coverage.svg',
  RIDE_APP = '/images/global/ride-icon.svg',
  BOTTOM_ARROW = '/images/global/srp-arrow.svg',
  MOBILE_BOTTOM_ARROW = '/images/global/mobile-bottom-arrow.svg',
  ANDROID = '/images/global/android.svg',
  ANDROID_SELECTED = '/images/global/android-selected.svg',
  IOS = '/images/global/ios.svg',
  IOS_SELECTED = '/images/global/ios-selected.svg',
  OTHER = '/images/global/other.svg',
  OTHER_SELECTED = '/images/global/other-selected.svg',
  EXPAND_ICON = '/images/global/expand-icon.svg',
  COLLAPSE_ICON = '/images/global/collapse-icon.svg'
}

export const START_QUOTE_DROPDOWNS = {
  suffixOptions: [
    { label: 'Jr.', value: 'Jr.' },
    { label: 'Sr.', value: 'Sr.' },
    { label: 'III', value: 'III' },
    { label: 'IV.', value: 'IV' },
    { label: 'V', value: 'V' }
  ]
}

export const vehicle_dropdowns = {
  Vehicle_Capacity: [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '6', value: '6' },
    { label: '> 6', value: '> 6' } // '> 6' matches the value we have in the DB
  ],
  Wheels: [
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '6', value: '6' },
    { label: '8', value: '8' }
  ]
}

export const aboutyou_dropdowns = {
  NumberOfPersons: [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '>6', value: '>6' } // '>6' matches the value we have in the DB
  ]
}

export const operator_details_dropdowns = {
  gender: [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Other', value: 'Other' }
  ],
  marital_status: [
    { label: 'Single', value: 'Single' },
    { label: 'Married', value: 'Married' },
    { label: 'Separated', value: 'Separated' },
    { label: 'Divorced', value: 'Divorced' },
    { label: 'Widowed', value: 'Widowed' },
    { label: 'Civil Union', value: 'Civil Union' }
  ],
  license_type: [
    {
      label: 'No foreign or international license',
      value: 'No foreign or international license'
    },
    { label: 'Out of state license', value: 'Out of state license' },
    {
      label: 'Foreign or international license',
      value: 'Foreign or international license'
    }
  ],

  suffixOptions: [
    { label: 'Jr.', value: 'Jr.' },
    { label: 'Sr.', value: 'Sr.' },
    { label: 'III', value: 'III' },
    { label: 'IV.', value: 'IV' },
    { label: 'V', value: 'V' }
  ],

  count: [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '>5', value: '>5' }
  ],

  claims: [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '>4', value: '>4' }
  ]
}

export const final_details_dropdown = {
  relationship: [
    { label: 'Spouse', value: 'Spouse' },
    { label: 'Child', value: 'Child' },
    { label: 'Parent', value: 'Parent' },
    { label: 'Sibling', value: 'Sibling' },
    { label: 'Grand Child', value: 'Grand Child' },
    { label: 'OTH', value: 'Other' }
  ],

  suffixOptions: [
    { label: 'Jr.', value: 'Jr.' },
    { label: 'Sr.', value: 'Sr.' },
    { label: 'III', value: 'III' },
    { label: 'IV.', value: 'IV' },
    { label: 'V', value: 'V' }
  ],

  states: [
    { label: 'AL', value: 'Alabama' },
    { label: 'AK', value: 'Alaska' },
    { label: 'AZ', value: 'Arizona' },
    { label: 'AR', value: 'Arkansas' },
    { label: 'CA', value: 'California' },
    { label: 'CO', value: 'Colorado' },
    { label: 'CT', value: 'Connecticut' },
    { label: 'DE', value: 'Delaware' },
    { label: 'FL', value: 'Florida' },
    { label: 'GA', value: 'Georgia' },
    { label: 'HI', value: 'Hawaii' },
    { label: 'ID', value: 'Idaho' },
    { label: 'IL', value: 'Illinois' },
    { label: 'IN', value: 'Indiana' },
    { label: 'IA', value: 'Iowa' },
    { label: 'KS', value: 'Kansas' },
    { label: 'KY', value: 'Kentucky' },
    { label: 'LA', value: 'Louisiana' },
    { label: 'ME', value: 'Maine' },
    { label: 'MD', value: 'Maryland' },
    { label: 'MA', value: 'Massachusetts' },
    { label: 'MI', value: 'Michigan' },
    { label: 'MN', value: 'Minnesota' },
    { label: 'MS', value: 'Mississippi' },
    { label: 'MO', value: 'Missouri' },
    { label: 'MT', value: 'Montana' },
    { label: 'NE', value: 'Nebraska' },
    { label: 'NV', value: 'Nevada' },
    { label: 'NH', value: 'New Hampshire' },
    { label: 'NJ', value: 'New Jersey' },
    { label: 'NM', value: 'New Mexico' },
    { label: 'NY', value: 'New York' },
    { label: 'NC', value: 'North Carolina' },
    { label: 'ND', value: 'North Dakota' },
    { label: 'OH', value: 'Ohio' },
    { label: 'OK', value: 'Oklahoma' },
    { label: 'OR', value: 'Oregon' },
    { label: 'PA', value: 'Pennsylvania' },
    { label: 'RI', value: 'Rhode Island' },
    { label: 'SC', value: 'South Carolina' },
    { label: 'SD', value: 'South Dakota' },
    { label: 'TN', value: 'Tennessee' },
    { label: 'TX', value: 'Texas' },
    { label: 'UT', value: 'Utah' },
    { label: 'VT', value: 'Vermont' },
    { label: 'VA', value: 'Virginia' },
    { label: 'WA', value: 'Washington' },
    { label: 'WV', value: 'West Virginia' },
    { label: 'WI', value: 'Wisconsin' },
    { label: 'WY', value: 'Wyoming' },
    { label: 'OTH', value: 'Other' }
  ],

  lienholder_states: [
    { label: 'AL', value: 'AL' },
    { label: 'AK', value: 'AK' },
    { label: 'AZ', value: 'AZ' },
    { label: 'AR', value: 'AR' },
    { label: 'CA', value: 'CA' },
    { label: 'CO', value: 'CO' },
    { label: 'CT', value: 'CT' },
    { label: 'DE', value: 'DE' },
    { label: 'FL', value: 'FL' },
    { label: 'GA', value: 'GA' },
    { label: 'HI', value: 'HI' },
    { label: 'ID', value: 'ID' },
    { label: 'IL', value: 'IL' },
    { label: 'IN', value: 'IN' },
    { label: 'IA', value: 'IA' },
    { label: 'KS', value: 'KS' },
    { label: 'KY', value: 'KY' },
    { label: 'LA', value: 'LA' },
    { label: 'ME', value: 'ME' },
    { label: 'MD', value: 'MD' },
    { label: 'MA', value: 'MA' },
    { label: 'MI', value: 'MI' },
    { label: 'MN', value: 'MN' },
    { label: 'MS', value: 'MS' },
    { label: 'MO', value: 'MO' },
    { label: 'MT', value: 'MT' },
    { label: 'NE', value: 'NE' },
    { label: 'NV', value: 'NV' },
    { label: 'NH', value: 'NH' },
    { label: 'NJ', value: 'NJ' },
    { label: 'NM', value: 'NM' },
    { label: 'NY', value: 'NY' },
    { label: 'NC', value: 'NC' },
    { label: 'ND', value: 'ND' },
    { label: 'OH', value: 'OH' },
    { label: 'OK', value: 'OK' },
    { label: 'OR', value: 'OR' },
    { label: 'PA', value: 'PA' },
    { label: 'RI', value: 'RI' },
    { label: 'SC', value: 'SC' },
    { label: 'SD', value: 'SD' },
    { label: 'TN', value: 'TN' },
    { label: 'TX', value: 'TX' },
    { label: 'UT', value: 'UT' },
    { label: 'VT', value: 'VT' },
    { label: 'VA', value: 'VA' },
    { label: 'WA', value: 'WA' },
    { label: 'WV', value: 'WV' },
    { label: 'WI', value: 'WI' },
    { label: 'WY', value: 'WY' }
  ]
}

export const cancellation_dropdown = {
  reason: [
    { label: 'Vehicle has been sold', value: 'Vehicle has been sold' },
    { label: 'Vehicle has been stolen', value: 'Vehicle has been stolen' },
    {
      label: 'Vehicle deemed a total loss',
      value: 'Vehicle deemed a total loss'
    },
    { label: 'Found cheaper price', value: 'Found cheaper price' },
    {
      label: 'Unhappy with service/company ',
      value: 'Unhappy with service/company'
    },
    { label: 'Other', value: 'Other' }
  ]
}

export enum ERROR_MESSAGES {
  REQUIRED = 'Required',
  NAME_MAX_CHAR_ERROR = 'Must be 30 characters or less',
  ADDRESS_MAX_CHAR_ERROR = 'Must be 50 characters or less',
  ZIP_MAX_CHAR_ERROR = 'Must be 5 characters',
  ZIP_VALIDATION_ERROR = 'Sorry Right now we are not available in your location',
  FIRST_NAME_VALIDATION_ERROR = 'First Name can only contain letters, a dash or an apostrophe',
  MIDDLE_NAME_VALIDATION_ERROR = 'Middle Name can only contain letters, a dash or an apostrophe',
  LAST_NAME_VALIDATION_ERROR = 'Last Name can only contain letters, a dash or an apostrophe',
  EMAIL_VALIDATION = 'Email must be in a Valid format',
  SELECT_VEHICLE_ERROR = 'Select any vehicle',
  TRIKE_ERROR = 'Vehicle Trike is required',
  WHEEL_ERROR = 'Vehicle Wheels are required',
  CAPACITY_ERROR = 'Vehicle Capacity is required',
  VIN_STATUS_ERROR = 'Please select any one',
  VIN_REQUIRED_ERROR = 'VIN is required',
  VIN_VALIDATION_ERROR = 'VIN should be 17 alphanumeric characters',
  VIN_MISMATCH_ERROR = "We are unable to verify your VIN. Please try again or select 'Add by Year/Make/Model'",
  YEAR_REQUIRED_ERROR = 'Vehicle Year is required',
  MAKE_REQUIRED_ERROR = 'Vehicle Make is required',
  MODAL_REQUIRED_ERROR = 'Vehicle Model is required',
  DOB_VALIDATION_ERROR = 'Date of Birth must be a valid date in the format of MM/DD/YYYY',
  DOB_FUTURE_DATE_ERROR = 'The entered date cannot be in the future',
  OPERATOR_MIN_AGE_VALIDATION_ERROR = 'Named Insured Age should be 18 Years',
  OPERATOR_MAX_AGE_VALIDATION_ERROR = 'Operator Age should not be greater than 99 Years',
  GENDER_ERROR = 'Please Select Gender',
  MARITAL_STATUS_ERROR = 'Please Select Marital Status',
  LICENSE_TYPE_ERROR = 'Please Select Operator’s License Type',
  RIDING_EXP_ERROR = 'Please Select Operator’s Riding experience',
  MOTORCYCLE_ENDORSEMENT_ERROR = 'Please Select Valid motorcycle endorsement',
  ATFAULT_CLAIMS_ERROR = 'Please enter one of the three fields',
  SELECT_VALUE_ERROR = 'Please Select a Value',
  CREDIT_SCORE_ERROR = 'Please select your credit score',
  COVERAGE_END_DATE_ERROR = 'Please select a date',
  SAFE_RIDER_PROGRAM_ERROR = 'Please select safe rider Program',
  FINAL_DETAILS_MIN_AGE_ERROR = 'The Minimum Age Should be 18 Years',
  LICENSE_REQUIRED_ERROR = 'License number Required',
  STATE_ERROR = 'State is required'
}

export const policyCoverages = {
  bigp: ['$30,000/$60,000', '$50,000/$100,000', '$100,000/$300,000'],
  pd: ['$25,000', '$50,000', '$100,000'],
  umbi: [
    '$30,000/$60,000',
    '$50,000/$100,000',
    '$100,000/$300,000',
    'Rejected -No Coverage'
  ],
  umpd: ['$25,000', '$50,000', '$100,000', 'Rejected -No Coverage'],
  pip: ['$2,500', 'Rejected -No Coverage'],
  mp: [
    '$500',
    '$1,000',
    '$2,000',
    '$5,000',
    '$10,000',
    'Rejected -No Coverage'
  ],
  ttpd: ['$2,500', '$5,000', '$7,500', '$10,000', 'Rejected -No Coverage']
}

export const vehicleType = {
  motorCycle: 'Motorcycle',
  atvutv: 'ATV/UTV',
  dirtbike: 'Dirtbike',
  ebike: 'E-BikeAssist',
  snowmobile: 'Snowmobile'
}

export const vehicleCoverages = {
  comp: ['$100', '$250', '$500', '$1,000', 'No Coverage'],
  coll: ['$100', '$250', '$500', '$1,000', 'No Coverage'],
  opt_me: [
    '$5,000',
    '$6,000',
    '$7,000',
    '$8,000',
    '$9,000',
    '$10,000',
    '$11,000',
    '$12,000',
    '$13,000',
    '$14,000',
    '$15,000',
    '$16,000',
    '$17,000',
    '$18,000',
    '$19,000',
    '$20,000',
    'No Coverage'
  ],
  opt_sd: [
    '$1,000',
    '$2,000',
    '$3,000',
    '$4,000',
    '$5,000',
    '$6,000',
    '$7,000',
    '$8,000',
    '$9,000',
    '$10,000',
    '$11,000',
    '$12,000',
    '$13,000',
    '$14,000',
    '$15,000',
    '$16,000',
    '$17,000',
    '$18,000',
    '$19,000',
    '$20,000',
    'No Coverage'
  ],
  d_ded: ['Yes', 'No', 'No Coverage'],
  pdp: ['Yes', 'No', 'No Coverage'],
  tripInt: ['$500', 'No Coverage'],
  subm: ['$2,500', 'No Coverage'],
  epu: ['Yes', 'No', 'No Coverage'],
  rc: ['Yes', 'No', 'No Coverage'],
  ra: ['Yes', 'No', 'No Coverage'],
  rr: ['$45/$1,350', 'No Coverage']
}

export const vehiclebodyType = {
  bodyType: [
    { label: 'reverse-trike', value: 'Autocycle' },
    { label: 'chopper', value: 'Chopper.' },
    { label: 'cruiser', value: 'Cruiser' },
    { label: 'off-road', value: 'DirtBike' },
    { label: 'dual-sport', value: 'Dual Purpose' },
    { label: 'scooter', value: 'Scooter' },
    { label: 'standard', value: 'Standard' },
    { label: 'Standard', value: 'Standard' },
    { label: 'sport', value: 'Sport' },
    { label: 'Sport', value: 'Sport' },
    { label: 'adventure-touring', value: 'Sport Touring' },
    { label: 'sport-touring', value: 'Sport Touring' },
    { label: 'Sport-Touring', value: 'Sport Touring' },
    { label: 'touring', value: 'Touring' }
  ]
}

export const anonymous_user = 'Anonymous User'
export const INVOICE_TYPES = {
  cancellation: 'cancellation',
  writtenOff: 'writtenOff',
  installment: 'installment',
  renewal: 'renewal',
  newBusiness: 'newBusiness',
  other: 'other',
  zeroDue: 'zeroDue'
}
